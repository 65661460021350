import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBzbJSYfyRQwqafN4mPPl31tb9okLGgNak",
  authDomain: "bustin-fe030.firebaseapp.com",
  databaseURL: "https://bustin-fe030-default-rtdb.firebaseio.com",
  projectId: "bustin-fe030",
  storageBucket: "bustin-fe030.appspot.com",
  messagingSenderId: "825705686613",
  appId: "1:825705686613:web:51c32c3261e876411516dc"
};

const app = initializeApp(firebaseConfig);

Vue.use(app)
Vue.config.productionTip = false

new Vue({
  vuetify,
  app,
  render: h => h(App)
}).$mount('#app')
