<template>
  <div class="hello text-center">
    <h1>{{this.default.word}} <v-btn color="#0063A8" dark icon @click="pinBust"><v-icon>mdi-pin</v-icon></v-btn></h1> 
    <p class='wordType'>{{this.default.type}}</p>
    <p class='definition'>{{this.default.definition}}</p>
    
    <v-btn color="#0063A8" dark @click="newWord">New Definition</v-btn>
    
  </div>
</template>

<script>
  import DA from'../assets/dict/DA.json'
  import DB from'../assets/dict/DB.json'
  import DC from'../assets/dict/DC.json'
  import DD from'../assets/dict/DD.json'
  import DE from'../assets/dict/DE.json'
  import DF from'../assets/dict/DF.json'
  import DG from'../assets/dict/DG.json'
  import DH from'../assets/dict/DH.json'
  import DI from'../assets/dict/DI.json'
  import DJ from'../assets/dict/DJ.json'
  import DK from'../assets/dict/DK.json'
  import DL from'../assets/dict/DL.json'
  import DM from'../assets/dict/DM.json'
  import DN from'../assets/dict/DN.json'
  import DO from'../assets/dict/DO.json'
  import DP from'../assets/dict/DP.json'
  import DQ from'../assets/dict/DQ.json'
  import DR from'../assets/dict/DR.json'
  import DS from'../assets/dict/DS.json'
  import DT from'../assets/dict/DT.json'
  import DU from'../assets/dict/DU.json'
  import DV from'../assets/dict/DV.json'
  import DW from'../assets/dict/DW.json'
  import DX from'../assets/dict/DX.json'
  import DY from'../assets/dict/DY.json'
  import DZ from'../assets/dict/DZ.json'
  
export default {
  name: 'BustaDefinitions',
  props: {
  },
  data: function() {
    return {
      default: {
        word: 'Busta',
        type: 'Noun',
        definition: 'A person, place, thing, word, concept, experience, anecdote, descriptor, plant, animal, period of time, spiritual practice, or diet that BUSTS.'
      },
      dictFiles: [
        DA,
        DB,
        DC,
        DD,
        DE,
        DF,
        DG,
        DH,
        DI,
        DJ,
        DK,
        DL,
        DM,
        DN,
        DO,
        DP,
        DQ,
        DR,
        DS,
        DT,
        DU,
        DV,
        DW,
        DX,
        DY,
        DZ,
      ],
      wordKeys: {
        Noun: "Busta",
        Verb: "Bustin",
        Adjective: "Busty",
        Adverb: "Bustily",
        Pronoun: "Buste"
      }
    }
  },
  methods:{
    pinBust(){
      let def = this.default.definition
      let type = this.default.type.toLowerCase()
      this.$emit('pinBust', {def: def, area: type})
    },
    newWord(){
      let file = this.getFile()
      let newDef = this.findGoodWord(Object.keys(file).length, file)
      this.default.word = this.wordKeys[newDef[0]]
      this.default.type = newDef[0]
      this.default.definition = newDef[1]
      this.$emit('bust')
    },
    randInRange(num){
      let min = Math.ceil(0)
      let max = Math.floor(num)
      return Math.floor(Math.random() * (max-min+1) + min)
    },
    findGoodWord(num, data){
      let wordFound = false
      while(!wordFound){
        let word = Object.values(data)[this.randInRange(num)]
        if(Object.values(word.MEANINGS).length > 0){
          return Object.values(word.MEANINGS)[0]
        }
      }
    },
    getFile(){
      return(this.dictFiles[Math.floor(Math.random() * this.dictFiles.length)])
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h1 {
    color: #0063A8;
    margin-bottom: 15px
  }
  .wordType{
    color: gray;
    font-size: 20px;
    margin-bottom: 15px
  }
  .definition{
    margin-bottom: 25px
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
