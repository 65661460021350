<template>
  <v-row>
    <v-col
      v-for="item in pinnedItems"
      :key="item.definition"
      cols="12" sm="12" md="6" lg="4"
    >
      <v-card 
        outlined 
        class='beep'
      >
        <v-card-title>
          <h3>Busta</h3>
        </v-card-title>
        <v-card-text class='text-left'>
          <p class='wordType'>{{area}}</p>
          <p class='definition'>{{item.definition}}</p>
        </v-card-text>'
        <v-card-actions>
          <v-btn @click='bust(item)' color="#0063A8" icon><v-icon>mdi-liquid-spot</v-icon></v-btn>
          <span >{{item.busts}}</span>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

  
export default {
  name: 'PinnedBusts',
  props: {
    area: String,
    pinnedItems: Array
  },
  data: function() {
    return {

    }
  },
  methods:{
    bust(item){
      console.log(item)
      this.$emit('bust', {def: item.definition, area: this.area})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    color: #0063A8;
    margin-bottom: 15px
  }
  .wordType{
    color: gray;
    margin-bottom: 15px;
  }
  .definition{
    color: black;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .beep{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
  }
</style>
