<template >
  <div id="app" >
    <v-container>
      <div>
        <v-card>
          
          <img style='width:100%; max-width:550px' src="./assets/logo.png">
          <p class='disclaimer'>This website was created to celebrate all the bustas out there bustin their busts bustily. If you read that and said 'whaaaaaat?' - you're not alone. This handy online application toolkit is a micro-learning powerhouse ready to educate, formulate, and populate the world with bustas in the know. Click through some of the many popular ways to bust, pin your favorites, and participate in the Busta University Success Tool, or BUST for short.</p>
          <v-divider style='margin-top: 15px; margin-bottom: 15px'></v-divider>
          <div style='margin-bottom: 25px'>
            <v-chip large color="#ff0000" dark>
              Total Busts:  {{this.bustsCount}}
            </v-chip>
          </div>
          
          <BustaDefinitions
            v-on:bust="bust"
            v-on:pinBust="pinBust"
          >
          </BustaDefinitions>
          <v-divider style='margin-top: 15px'></v-divider>
          <h1 style='margin-top: 15px'>Pinned Busts</h1>
          <p class='disclaimer'>
            This area is dedicated to the best of the best busts. Pin your favorite busts above and then bust on the busts below and see which busts the busta community believes busts best.
          </p>
          <v-tabs
            v-model="pinnedTab"
            centered
            grow
          >
            <v-tab
              v-for="item in bustaTabs"
              :key=item
            >
              {{item}}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model='pinnedTab'>
            <v-tab-item key='Busta'>
              <PinnedBusts
                v-bind:pinnedItems="noun"
                v-bind:area="'noun'"
                v-on:bust="bustOnAPinnedBust"
              >
              </PinnedBusts>
            </v-tab-item>
            <v-tab-item key='Bustin'>
              <PinnedBusts
                v-bind:pinnedItems="verb"
                v-bind:area="'verb'"
                v-on:bust="bustOnAPinnedBust"
              >
              </PinnedBusts>
            </v-tab-item>
            <v-tab-item key='Busty'>
              <PinnedBusts
                v-bind:pinnedItems="adjective"
                v-bind:area="'adjective'"
                v-on:bust="bustOnAPinnedBust"
              >
              </PinnedBusts>
            </v-tab-item>
            <v-tab-item key='Bustily'>
              <PinnedBusts
                v-bind:pinnedItems="adverb"
                v-bind:area="'adverb'"
                v-on:bust="bustOnAPinnedBust"
              >
              </PinnedBusts>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>

    </v-container>
    
    
  </div>
</template>

<script>
import BustaDefinitions from './components/BustaDefinitions.vue'
import PinnedBusts from './components/PinnedBusts.vue'

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, set, get, child } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyBzbJSYfyRQwqafN4mPPl31tb9okLGgNak",
  authDomain: "bustin-fe030.firebaseapp.com",
  databaseURL: "https://bustin-fe030-default-rtdb.firebaseio.com",
  projectId: "bustin-fe030",
  storageBucket: "bustin-fe030.appspot.com",
  messagingSenderId: "825705686613",
  appId: "1:825705686613:web:51c32c3261e876411516dc"
};

initializeApp(firebaseConfig);

const db = getDatabase();
const bustsRef = ref(db, 'busts')
const pinnedBustsRef = ref(db, 'pinnedBusts')
export default {
  name: 'App',
  components: {
    BustaDefinitions,
    PinnedBusts
  },
  data: function(){
    return {
      pinnedTab: null,
      bustaTabs: ['Busta', 'Bustin', 'Busty', 'Bustily'],
      bustsCount: 'loading..',
      noun: [
        {
          definition: 'loading..',
          busts: 0
        }
      ],
      verb: [
        {
          definition: 'loading..',
          busts: 0
        }
      ],
      adjective: [
        {
          definition: 'loading..',
          busts: 0
        }
      ],
      adverb: [
        {
          definition: 'loading..',
          busts: 0
        }
      ]
    }
    
  },
  methods:{
    bustsCounter(){
      onValue(bustsRef, (snapshot) => {
        console.log(snapshot.val())
        this.bustsCount = snapshot.val()
      })
    },
    getPinnedBusts(){
      get(pinnedBustsRef).then((snapshot) => {
        let pinnedBusts = snapshot.val()
        this.noun = pinnedBusts.noun
        this.verb = pinnedBusts.verb
        this.adjective = pinnedBusts.adjective
        this.adverb = pinnedBusts.adverb
      })
    },
    bust(){
      set(bustsRef, this.bustsCount + 1)
    },
    pinBust(data){
      console.log('lala')
      console.log(data)
      if(data.area == 'noun'){
        let newIndex = this.noun.length
        let newBust = {
          busts: 0,
          definition: data.def
        }
        set(child(pinnedBustsRef, ('/noun/' + newIndex)), newBust)
        this.getPinnedBusts()
      } else if (data.area == 'verb'){
       let newIndex = this.verb.length
        let newBust = {
          busts: 0,
          definition: data.def
        }
        set(child(pinnedBustsRef, ('/verb/' + newIndex)), newBust)
        this.getPinnedBusts()
      } else if (data.area == 'adjective'){
        let newIndex = this.adjective.length
        let newBust = {
          busts: 0,
          definition: data.def
        }
        set(child(pinnedBustsRef, ('/adjective/' + newIndex)), newBust)
        this.getPinnedBusts()
      } else if (data.area == 'adverb'){
        let newIndex = this.adverb.length
        let newBust = {
          busts: 0,
          definition: data.def
        }
        set(child(pinnedBustsRef, ('/adverb/' + newIndex)), newBust)
        this.getPinnedBusts()
      }
    },
    bustOnAPinnedBust(data){
      if(data.area == 'noun'){
        let tmpIndex = this.indexOfPinnedBust(data.def, this.noun)
        this.noun[tmpIndex].busts += 1 
        set(child(pinnedBustsRef, ('noun/' + tmpIndex + '/busts')), this.noun[tmpIndex].busts)
      } else if (data.area == 'verb'){
        let tmpIndex = this.indexOfPinnedBust(data.def, this.verb)
        this.verb[tmpIndex].busts += 1 
        set(child(pinnedBustsRef, ('verb/' + tmpIndex + '/busts')), this.verb[tmpIndex].busts)
      } else if (data.area == 'adjective'){
        let tmpIndex = this.indexOfPinnedBust(data.def, this.adjective)
        this.adjective[tmpIndex].busts += 1 
        set(child(pinnedBustsRef, ('adjective/' + tmpIndex + '/busts')), this.adjective[tmpIndex].busts)
      } else if (data.area == 'adverb'){
        let tmpIndex = this.indexOfPinnedBust(data.def, this.adverb)
        this.adverb[tmpIndex].busts += 1 
        set(child(pinnedBustsRef, ('adverb/' + tmpIndex + '/busts')), this.adverb[tmpIndex].busts)
      }
    },
    indexOfPinnedBust(def, area){
      for(let i = 0; i < area.length; i++){
        if(area[i].definition == def){
          return i
        }
      }
      return -1
    }
  },
  mounted(){
    this.bustsCounter()
    this.getPinnedBusts()
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  .v-card {
    padding-top:25px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }

  img{
    margin-bottom:25px
  }

  .disclaimer{
    color: gray;
    margin-bottom: 25px
  }
  .whiteText{
    color: white
  }
</style>
